<template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="2xl"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Cargo info</h2>
        </div>

        <div slot="body">
            <div v-if="loading" class="flex flex__justify-center w__100-p">
                <spinner />
            </div>

            <div v-else class="cargo">
                <div class="border__bottom-grey-1 pb-4">
                    <div class="mb-3 f-w-medium" v-text="`Totals`" />

                    <div class="flex">
                        <div class="flex flex__column">
                            <div class="cargo__totals-item">Lines: {{ cargo.total.lines_count }}</div>
                            <div class="cargo__totals-item">Items: {{ cargo.total.items_count }}</div>
                        </div>
                        <div class="flex flex__column">
                            <div class="cargo__totals-item-min mr-6">Volume: {{ total.volume }}</div>
                            <div class="cargo__totals-item-min mr-6">Weight: {{ total.weight }}</div>
                        </div>
                        <div class="flex flex__column">
                            <div class="cargo__totals-item-min">Loading meters: {{ total.loadingMeters }}</div>
                            <div class="cargo__totals-item-min">Package types: {{ total.packageTypes.length ? total.packageTypes : '-' }}</div>
                        </div>
                    </div>
                </div>

                <div class="cargo__items">
                    <div v-for="item, index in cargo.lines" :key="index" class="cargo__item">
                        <div class="pt-4 pb-4">
                            <div class="f-w-medium">Goods line {{ index + 1 }}</div>
                        </div>
    
                        <div class="flex flex__wrap">
                            <modal-field class="cargo__form-item mr-4" label="Items">
                                <input
                                    v-model.number="item.items_count"
                                    class="w-full"
                                    type="number"
                                    @focus="focusNumberInput(item, 'items_count')" 
                                    @blur="blurNumberInput(item, 'items_count')"
                                />
                            </modal-field>
    
                            <modal-field
                                class="cargo__form-item mr-4"
                                :label="`Volume ${item.volume_unit || ''}`"
                            >
                                <div class="flex">
                                    <input
                                        v-model.number="item.volume"
                                        class="w-full"
                                        type="number"
                                        :disabled="!isAdmin"
                                        @focus="focusNumberInput(item, 'volume')"
                                        @blur="blurNumberInput(item, 'volume')"
                                    />
                                    <multiselect v-model="item.volume_unit" :disabled="!isAdmin" :hide-selected="true" :options="measurementUnits.volume" class="ml-2 cargo__unit" placeholder="" />
                                </div>
                            </modal-field>  
    
                            <modal-field class="cargo__form-item mr-4" label="Loading meters">
                                <input
                                    v-model.number="item.loading_meters"
                                    class="w-full"
                                    type="number"
                                    :disabled="!isAdmin"
                                    @focus="focusNumberInput(item, 'loading_meters')"
                                    @blur="blurNumberInput(item, 'loading_meters')"
                                />
                            </modal-field>
    
                            <modal-field class="cargo__form-item" :label="`Length ${item.length_unit || ''}`">
                                <div class="flex">
                                    <input
                                        v-model.number="item.length"
                                        class="w-full"
                                        type="number"
                                        :disabled="!isAdmin"
                                        @focus="focusNumberInput(item, 'length')"
                                        @blur="blurNumberInput(item, 'length')"
                                    />
                                    <multiselect v-model="item.length_unit" :disabled="!isAdmin" :options="measurementUnits.length" class="ml-2 cargo__unit" placeholder="" Shipping mark />
                                </div>
                            </modal-field>
    
                            <modal-field class="cargo__form-item mr-4" label="Type">
                                <multiselect v-model="item.good_type_id" :disabled="!isAdmin" :options="types" label="name" track-by="id" placeholder="">
                                    <template slot="singleLabel" slot-scope="props">
                                        <div>{{ props.option.name }}</div>
                                      </template>
                                    
                                    <template slot="option" slot-scope="props">
                                        <div>{{ props.option.name }}</div>
                                      </template>
                                </multiselect>
                            </modal-field>
    
                            <modal-field class="cargo__form-item mr-4" :label="`Weight ${item.weight_unit || ''}`">
                                <div class="flex">
                                    <input
                                        v-model.number="item.weight"
                                        class="w-full"
                                        type="number"
                                        :disabled="!isAdmin"
                                        @focus="focusNumberInput(item, 'weight')"
                                        @blur="blurNumberInput(item, 'weight')"
                                    />
                                    <multiselect v-model="item.weight_unit" :disabled="!isAdmin" :options="measurementUnits.weight" class="ml-2 cargo__unit" placeholder="" Shipping mark />
                                </div>
                            </modal-field>
    
                            <modal-field class="cargo__form-item mr-4" label="Pallets">
                                <input
                                    v-model.number="item.pallet_space"
                                    class="w-full"
                                    type="number"
                                    :disabled="!isAdmin"
                                    @focus="focusNumberInput(item, 'pallet_space')"
                                    @blur="blurNumberInput(item, 'pallet_space')"
                                />
                            </modal-field>
    
                            <modal-field class="cargo__form-item" :label="`Width ${item.width_unit || ''}`">
                                <div class="flex">
                                    <input
                                        v-model.number="item.width"
                                        class="w-full"
                                        type="number"
                                        :disabled="!isAdmin"
                                        @focus="focusNumberInput(item, 'width')"
                                        @blur="blurNumberInput(item, 'width')"
                                    />
                                    <multiselect v-model="item.width_unit" :disabled="!isAdmin" :options="measurementUnits.width" class="ml-2 cargo__unit" placeholder="" Shipping mark />
                                </div>
                            </modal-field>

                            <modal-field class="cargo__form-item mr-4" label="Shipping mark">
                                <div class="flex">
                                    <input
                                        v-model="item.tag"
                                        class="w-full"
                                        type="text"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </modal-field>
    
                            <modal-field class="cargo__form-item cargo__form-item--max mr-4" label="Description">
                                <input
                                    v-model="item.description"
                                    class="w-full"
                                    type="text"
                                    :disabled="!isAdmin"
                                />
                            </modal-field>
    
                            <modal-field class="cargo__form-item" :label="`Height ${item.height_unit || ''}`">
                                <div class="flex">
                                    <input
                                        v-model.number="item.height"
                                        class="w-full"
                                        type="number"
                                        :disabled="!isAdmin"
                                        @focus="focusNumberInput(item, 'height')"
                                        @blur="blurNumberInput(item, 'height')"
                                    />
                                    <multiselect v-model="item.height_unit" :disabled="!isAdmin" :options="measurementUnits.height" class="ml-2 cargo__unit" placeholder="" Shipping mark />
                                </div>
                            </modal-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button v-if="isAdmin" class="px-10 btn-primary" :disabled="btnLoading" @click="fetchSave">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">{{ isAdmin ? 'Cancel' : 'Close' }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';

import Spinner from '~/components/Spinner.vue';

import { roundWithEpsilon } from '@/utils/roundNumber.js';


const MEASUREMENT_UNITS = {
    length: ['m', 'cm', 'ft', 'in', 'yd'],
    weight: ['kg', 't', 'lb', 'oz', 'st'],
    volume: ['m3', 'mm3', 'cm3', 'dm3', 'ft3', 'in3', 'yd3', 'ml', 'cl', 'dl', 'l'],
};

const round = (num) => {
    if (Number.isInteger(num)) {
        return num;
    }

    return Math.round((num + Number.EPSILON) * 100) / 100;
};

const formatToCm = (value, unit) => {
    if (unit === 'm') {
        return roundWithEpsilon(value * 100);
    }

    if (unit === 'ft') {
        return roundWithEpsilon(value * 30,48);
    }

    if (unit === 'in') {
        return roundWithEpsilon(value * 2,54);
    }

    if (unit === 'yd') {
        return roundWithEpsilon(value * 91,44);
    }

    return roundWithEpsilon(value);
};

export default {
    name: 'CargoModal',

    components: {
        Spinner,
    },

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        }
    },

    data() {
        return {
            type: null,
            submitter: false,
            loading: true,
            btnLoading: false,

            cargo: {},
            types: [],
            measurementUnits: {
                width: MEASUREMENT_UNITS.length,
                height: MEASUREMENT_UNITS.length,
                length: MEASUREMENT_UNITS.length,
                weight: MEASUREMENT_UNITS.weight,
                volume: MEASUREMENT_UNITS.volume,
            },
        }
    },

    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },

        isAdmin() {
            return this.user.level === 'super';
        },

        total() {
            const total = {
                volume: {},
                weight: {},
                loadingMeters: 0,
                packageTypes: new Set(),
            };
            this.cargo?.lines?.map(line => {
                total.volume[line.volume_unit] = (total.volume[line.volume_unit] || 0) + line.volume;
                total.weight[line.weight_unit] = (total.weight[line.weight_unit] || 0) + line.weight;
                total.loadingMeters = total.loadingMeters + line.loading_meters;
                total.packageTypes = total.packageTypes.add(line.good_type_id?.name || line.good_type_id?.code);
            });

            return {
                volume: 
                    Object.keys(total.volume)
                        .reduce(
                            (accum, volumeKey) => {
                                accum.push(`${round(total.volume[volumeKey])} ${volumeKey}`);

                                return accum;
                            },
                            []
                        )?.join(', '),
                weight:
                    Object.keys(total.weight)
                        .reduce(
                            (accum, weightKey) => {
                                accum.push(`${round(total.weight[weightKey])} ${weightKey}`);

                                return accum;
                            },
                            []
                    )?.join(', '),
                loadingMeters: 
                    round(total.loadingMeters),
                packageTypes: 
                    Array.from(total.packageTypes).filter(type => type).join(', '),
            };
        },
    },

    methods: {
        focusNumberInput(item, key) {
            if (!item[key]) {
                item[key] = '';
            }
        },

        blurNumberInput(item, key) {
            if ((!item[key] || item[key] < 0) && key === 'items_count') {
                item[key] = 1;

                return;
            }

            if (!item[key]) {
                item[key] = 0;
            }
        },
        
        async fetchTypes() {
            const result = await axios.get(this.$apiUrl.goodTypeDictionary.search + `?carrier_name=${this.value.carrier_data ? this.value.carrier_data.carrier_name : this.value.carrier_name}&carrier_id=${this.value.carrier_data ? this.value.carrier_data.carrier_id : this.value.carrier_id}`);

            this.types = result.data.filter(item => item.name || item.code).map(item => ({ ...item, name: item.name || item.code }));
        },

        async fetchCargo() {
            const { data: { data } } = await axios.get(`/api/consignments/${this.value.uuid}/cargo`)

            this.cargo = data;
        },

        async fetchSave() {
            this.btnLoading = true;

            const result = this.cargo.lines;

            result.forEach(item => {
                item.good_type_id = item?.good_type_id?.id || null;
                item.packages_count = item.items_count;
                delete item.items_count;
            });

            try {
                await axios.patch(`/api/consignments/${this.value.uuid}/cargo`, { lines: this.cargo.lines });

                this.$snotify.success(`Successfully`);

                this.$emit('hide');
                this.$emit('refreshTable');
            } catch(error) {
                this.$snotify.error(error.response.data.errors?.join(', '));
            } finally {
                this.btnLoading = false;
            }
        }
    },

    async created() {
        await this.fetchTypes();
        await this.fetchCargo();

        this.cargo.lines.forEach(item => {
            const numericFields = ['height', 'items_count', 'length', 'loading_meters', 'pallet_space', 'volume', 'weight', 'width'];

            numericFields.forEach(field => {
                if (!item[field]) {
                    item[field] = 0;
                }

                if (['height', 'length', 'width'].includes(field)) {
                    item[field] = formatToCm(item[field], item[`${field}_unit`]);
                    item[`${field}_unit`] = 'cm';

                }
            });

            if (item.good_type_id) {
                item.good_type_id = this.types.find(type => { return type.id === item.good_type_id });
            }
        })

        this.loading = false;
    },

    validations: {
    }
}
</script>

<style lang="scss">
.cargo {
    &__totals-item {
        min-width: 180px;
        max-width: 180px;

        &-min {
            min-width: 180px;
            max-width: unset;
        }
    }

    &__form-item {
        width: calc(25% - 12px);
        margin-bottom: 16px;

        &--max {
            width: calc(50% - 8px);
        }
    }

    &__items {
        max-height: calc(100vh - 326px);
        min-height: calc(100vh - 326px);
        overflow: auto;
    }

    &__item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 8px;
        padding-top: 4px;


        &:last-child {
            border: none;
        }
    }

    &__unit {
        width: 95px;
        min-width: 95px;
    }
}
</style>
