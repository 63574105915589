const isFloat = (value) => {
    return value % 1 !== 0;
};

export const roundNumber = (value) => {
    let newValue = +value;
    
    if (typeof newValue === 'number' && isFloat(newValue)) {
        return newValue.toFixed(2);
    }

    return value;
};

export const roundWithEpsilon = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};